import { useEffect } from 'react';

import api from 'src/axios-config';

import { useAuth } from './use-auth';
import { useDoctor } from './use-doctor';

const noAuthorization = [
  'auth/login/',
  'auth/registration/',
  'v1/projects/',
  'auth/password/reset/',
  'auth/password/reset/confirm/',
];

const useAxios = () => {
  const { auth, setAuth } = useAuth();
  const { lang } = useDoctor();

  useEffect(() => {
    const requestIntercept = api.interceptors.request.use(
      (config) => {
        if (!config.headers.Authorization) {
          config.headers.Authorization = `Token ${auth}`;
        }
        if (noAuthorization.includes(config.url)) {
          config.headers.Authorization = null;
        }
        config.headers['Accept-Language'] = lang;
        return config;
      },
      (error) => Promise.reject(error)
    );

    const responseIntercept = api.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error?.response?.status === 401) {
          setAuth(false);
        }
        return Promise.reject(error);
      }
    );

    return () => {
      api.interceptors.request.eject(requestIntercept);
      api.interceptors.response.eject(responseIntercept);
    };
  }, [auth, lang, setAuth]);

  return api;
};

export default useAxios;
