import i18next from 'i18next';
import Cookies from 'universal-cookie';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const version = 16;

export const supportedLngs = ['hr', 'en-GB', 'en', 'pt-PT'];

const cookies = new Cookies();

const setLang = () => {
  const lang = cookies.get('i18next');
  if (lang) {
    cookies.set('language-cached', true, { path: '/', maxAge: 5 });
    return lang;
  }
  return 'en';
};

i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs,
    lng: setLang(),
    fallbackLng: 'en',
    debug: false,
    // Options for language detector
    detection: {
      order: ['cookie', 'path'],
      caches: ['cookie'],
    },
    // react: { useSuspense: false },
    backend: {
      loadPath: `/assets/locales/{{lng}}/v${version}-translation.json`,
    },
  });

export default i18next;
